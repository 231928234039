import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`In a nutshell, I am a programmer and help teams solve human problems with technology. I specialize in building software for clients ranging from individuals and small-businesses all the way to large enterprise corporations and governments.`}</p>
    <p>{`Over the past ten years, I have done work in software development, mobile app creation, front-end/back-end web, and database/server management.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      