import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Recent Projects`}</h2>
    <ProjectCard title="The Windows Club" link="https://www.thewindowsclub.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Designed and developed a SEO-friendly WordPress theme based on Genesis 
  Framework using the latest capabilities of WordPress. Met threshold for 
  all Core Web Vitals in order to benefit from the associated ranking signal in SERPs.
    </ProjectCard>
    <ProjectCard title="Cricfeed" link="https://play.google.com/store/apps/details?id=xyz.cricfeed" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Designed and led the development of Cricfeed formally known as Cricket Live, 
  an extremely lightweight app for Cricket fans with superfast scoreboard and 
  ball-by-ball commentary. The project is the successor of Cricket Live based 
  on Xamarin Forms, rewritten in Kotlin for Android.
    </ProjectCard>
    <h2>{`Popular Projects`}</h2>
    <ProjectCard title="FixWin" link="https://bit.ly/3kjOb7v" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Developed a freeware for Windows 7 & Vista that features over 130 tweaks 
  from Personalization, User Accounts & UAC, System's Performance & Security Settings, 
  Internet Explorer, and Network Optimization.
    </ProjectCard>
    <ProjectCard title="Ultimate Windows Tweaker" link="https://bit.ly/38qMyCa" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Developed a freeware for Windows 7 & Vista that features over 130 tweaks from 
  Personalization, User Accounts & UAC, System's Performance & Security Settings, 
  Internet Explorer, and Network Optimization.
    </ProjectCard>
 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      